<template>
  <div>
    <b-card
      id="product-form-create"
      ref="product-form-create"
      no-body
      class="custom-border-top-1px"
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN CƠ BẢN
        </b-badge>
        <div class="d-flex flex-column">
          <b-button
            v-b-tooltip.hover="'Tỉnh, loại, quận/huyện, phường/xã, đường, số nhà'"
            :variant="getStatusCheckDuplicateBtn()"
            size="sm"
            @click="handleCheckProductUnique"
          >
            <feather-icon icon="AlertCircleIcon" />
            Kiểm tra
          </b-button>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <!--              nhóm sản phẩm: nhà phố | khách sạn & chdv-->
            <!--            <dynamic-form-->
            <!--              v-if="productMenuTypeVI !== 'ban'"-->
            <!--              :id="'filter-form-nhom-san-pham'"-->
            <!--              :value="value.product_type"-->
            <!--              :type="'radio'"-->
            <!--              :label="'Nhóm sản phẩm'"-->
            <!--              :class="'form-group-hor'"-->
            <!--              :options="PRODUCT_TYPE.filter(item => item.validBy.includes('group1'))"-->
            <!--              @update-value="val => value.product_type = val"-->
            <!--            />-->

            <!--            <div-->
            <!--              v-else-->
            <!--              class="form-group-hor mb-1"-->
            <!--            >-->
            <!--              <label>Nhóm sản phẩm</label>-->
            <!--              <div class="font-weight-bolder text-primary">-->
            <!--                Nhà phố-->
            <!--              </div>-->
            <!--            </div>-->

            <!--              hình thức-->
            <dynamic-form
              :id="'product-form-hinh-thuc'"
              :value="value.formality"
              :type="'radio'"
              :label="'Hình thức'"
              :required="required.formality ? 'required' : ''"
              :class="'form-group-hor'"
              :options="FORMALITY_PRODUCT.filter(item => item.validBy.includes(productMenuTypeVI || 'cho-thue'))"
              @update-value="val => value.formality = val"
            />

            <!--              loại-->
            <dynamic-form
              :id="'product-form-loai'"
              :value="value.type"
              :type="'select'"
              :label="'Loại'"
              :required="required.type ? 'required' : ''"
              :class="'form-group-hor'"
              :options="TYPE_PRODUCT.filter(item => item.validBy.includes(value.formality || 'cho-thue'))"
              @update-value="val => value.type = val"
            />

            <!--              tỉnh-->
            <dynamic-form
              v-if="value.type !== 'warehouse'"
              :id="'product-form-tinh'"
              :value="value.province_id"
              :type="'radio'"
              :label="'Tỉnh'"
              value-field="id"
              :required="required.province_id ? 'required' : ''"
              :class="'form-group-hor'"
              :options="PROVINCE"
              @update-value="val => value.province_id = val"
            />

            <!--              tỉnh : case type === kho xưởng-->
            <dynamic-form
              v-else
              :id="'product-form-tinh-kho-xuong'"
              :value="value.province_id"
              :type="'select'"
              :label="'Tỉnh'"
              value-field="id"
              text-field="name"
              :required="required.province_id ? 'required' : ''"
              :class="'form-group-hor'"
              :options="$store.state.app.provinces"
              @update-value="val => value.province_id = val"
            />

            <!--              quận huyện-->
            <dynamic-form
              :id="'product-form-quanhuyen'"
              :value="value.district_id"
              :type="'select'"
              :label="'Quận/Huyện'"
              :required="required.district_id ? 'required' : ''"
              value-field="id"
              text-field="name"
              :class="'form-group-hor'"
              :options="districts"
              @update-value="val => value.district_id = val"
            />

            <!--              Phường/Xã-->
            <dynamic-form
              :id="'product-form-phuongxa'"
              :value="value.ward_id"
              :type="'select'"
              :label="'Phường/Xã'"
              :required="required.ward_id ? 'required' : ''"
              value-field="id"
              text-field="name"
              :class="'form-group-hor'"
              :options="wards"
              @update-value="val => value.ward_id = val"
            />

            <!--              Đường-->
            <dynamic-form
              :id="'product-form-duong'"
              :value="value.street_id"
              :type="'select'"
              :label="'Đường'"
              value-field="id"
              text-field="name"
              :required="required.street_id ? 'required' : ''"
              :select-default="false"
              :class="'form-group-hor'"
              :options="streets"
              @update-value="val => value.street_id = val"
            />

            <!--              Số nhà-->
            <dynamic-form
              :id="'product-form-sonha'"
              :value="value.house_number"
              :required="required.house_number ? 'required houseNumber' : ''"
              :type="'text'"
              :label="'Số nhà'"
              append-icon="AlertCircleIcon"
              append-tooltip="12/1-12/3 <br/>
                              12A-12B-12C <br/>
                              12-14 <br/>
                              12Bis <br/>
                              12Ter <br/>
                              12A <br/>
                              12 <br/>
                              12/12 <br/>
                              242A-244 OR 242-244A <br/>
                              12-14-16 <br/>
                              12A1 <br/>
                              12A1 <br/>
                              A91/A92 <br/>
                              A91 <br/>
                              602/29C <br/>"
              :class="'form-group-hor'"
              @update-value="val => value.house_number = val"
            />
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >

            <!--            ngang-->
            <dynamic-form
              :id="'product-form-ngang'"
              :value="value.width"
              :type="'number'"
              placeholder="Ngang"
              :label="'Ngang'"
              :required="required.width ? 'required' : ''"
              :class="'form-group-hor'"
              append-text="m"
              @update-value="val => value.width = val"
            />

            <!--            dài-->
            <dynamic-form
              :id="'product-form-length'"
              :value="value.length"
              :type="'number'"
              placeholder="m"
              :label="'Dài'"
              :required="required.length ? 'required' : ''"
              :class="'form-group-hor'"
              append-text="m"
              @update-value="val => value.length = val"
            />

            <!--            Số lầu-->
            <dynamic-form
              v-if="value.type!=='san-thuong'"
              :id="'product-form-floor_number'"
              :value="value.floor_number"
              :type="'number'"
              placeholder="Số lầu"
              :label="'Số lầu'"
              :required="required.floor_number ? 'required' : ''"
              :class="'form-group-hor'"
              append-text="lầu"
              @update-value="val => value.floor_number = val"
            />

            <!--            Số phòng-->
            <dynamic-form
              v-if="value.type==='khach-san'"
              :id="'product-form-room_number_total'"
              :value="value.room_number_total"
              :type="'number'"
              placeholder="Số phòng"
              :required="required.room_number_total ? 'required' : ''"
              :label="'Số phòng'"
              :class="'form-group-hor'"
              @update-value="val => value.room_number_total = val"
            />

            <!--            Diện tích-->
            <dynamic-form
              :id="'product-form-acreage'"
              :value="value.acreage"
              :type="'number'"
              placeholder="Diện tích"
              :label="'Diện tích'"
              :required="required.acreage ? 'required' : ''"
              :class="'form-group-hor'"
              append-text="m2"
              @update-value="val => value.acreage = val"
            />

            <div class="form-group-hor mb-1">
              <label>Giá</label>
              <div class="d-flex flex-column gap-25">
                <div class="d-flex gap-25">
                  <b-input-group>
                    <b-form-input
                      v-model="value.input_price"
                      placeholder="Giá"
                    />
                  </b-input-group>

                  <v-select
                    id="filter-form-price-from-currency"
                    v-model="value.currency"
                    class="v-select-no-wrap"
                    :reduce="(option) => option.value"
                    :label="'text'"
                    :options="currencyList"
                    :clearable="false"
                  />
                </div>
              </div>
            </div>

            <!--            sang nhượng-->
            <dynamic-form
              v-if="value.formality === 'sang-nhuong'"
              :id="'product-form-tranfserprice-currency'"
              :value="value.transfer_price"
              :type="'number'"
              placeholder="Sang nhượng"
              :sub-value="value.transfer_price_currency"
              :with-dropdown-options="CURRENCY.filter(c => c.validBy.includes('vnd'))"
              :label="'Sang nhượng'"
              :required="required.transfer_price ? 'required' : ''"
              :class="'form-group-hor'"
              @update-value="val => value.transfer_price = val"
              @update-sub-value="val => value.transfer_price_currency = val"
            />

            <!--            Thanh toán-->
            <dynamic-form
              :id="'product-form-type_of_payment'"
              :value="value.type_of_payment"
              :type="'radio'"
              placeholder="Thanh toán"
              :label="'Thanh toán'"
              :required="required.type_of_payment ? 'required' : ''"
              :options="TYPE_OF_PAYMENT.filter(item => item.validBy.includes(value.formality || 'cho-thue'))"
              :class="'form-group-hor'"
              @update-value="val => value.type_of_payment = val"
            />

            <!--            Số sao-->
            <!--            <dynamic-form-->
            <!--              v-if="value.type==='khach-san'"-->
            <!--              :id="'product-form-ratingStars'"-->
            <!--              :type="'rating'"-->
            <!--              :value="value.rating_stars"-->
            <!--              stars="7"-->
            <!--              placeholder="Số sao"-->
            <!--              :label="'Số sao'"-->
            <!--              :class="'form-group-hor'"-->
            <!--              @update-value="val => value.rating_stars = val"-->
            <!--            />-->

            <br>
            <div
              class="form-group-hor text-primary font-weight-bolder"
            >
              <label class="font-small-4 text-primary"><strong>Giá tổng</strong>
                <feather-icon
                  v-b-tooltip.hover.html="tooltipPriceDisplayCustomer"
                  class="ml-25"
                  icon="AlertCircleIcon"
                />
              </label>
              <div>
                {{ value.priceDisplay }}
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            lg="4"
          >
            <!--            chủ nhà-->
            <dynamic-form
              v-if="value.host_name"
              :id="'product-form-chunha'"
              :value="value.host_name"
              :type="'text'"
              :label="'Chủ nhà'"
              placeholder="Họ tên"
              :required="required.host_name ? 'required' : ''"
              :class="'form-group-hor'"
              @update-value="val => value.host_name = val"
            />

            <!--            SĐT-->
            <dynamic-form
              :id="'product-form-sdt1'"
              :value="value.host_phone1"
              :type="'text'"
              :label="'SĐT'"
              placeholder="SĐT"
              :required="required.host_phone1 ? 'required' : ''"
              :class="'form-group-hor'"
              @update-value="val => value.host_phone1 = val"
            />

            <div
              v-if="value.host_phone2"
              class="form-group-hor"
            >
              <label>
                SĐT cũ
              </label>
              <div class="text-primary">
                {{ value.host_phone2 }}
              </div>
            </div>

            <!--            ẩn sdt-->
            <dynamic-form
              :id="'product-form-is_hidden_phone'"
              :value="value.is_hidden_phone"
              :type="'checkbox'"
              :label="' '"
              :sub-label="'Ẩn số chủ nhà'"
              :class="'form-group-hor'"
              @update-value="val => value.is_hidden_phone = val"
            />

            <!--            nguồn-->
            <dynamic-form
              :id="'product-form-ref'"
              :value="value.source_id"
              :type="'select'"
              :label="'Nguồn'"
              :options="users"
              text-field="fullname"
              value-field="id"
              :required="required.source_id ? 'required' : ''"
              :with-info="true"
              :select-default="false"
              placeholder="Nhân viên"
              :class="'form-group-hor'"
              @update-value="val => value.source_id = val"
            />

            <!--            Người hỗ trợ-->
            <dynamic-form
              :id="'product-form-contact'"
              :value="value.contact_id"
              :type="'select'"
              :label="'Người hỗ trợ'"
              :options="users"
              text-field="fullname"
              value-field="id"
              :required="required.contact_id ? 'required' : ''"
              :with-info="true"
              :select-default="false"
              placeholder="Nhân viên"
              :class="'form-group-hor'"
              @update-value="val => value.contact_id = val"
            />

            <!--            Kiểu nhà-->
            <dynamic-form
              :id="'product-form-kieunha'"
              :value="houseStyleList"
              :options="HOUSE_STYLE"
              :type="'checkbox-group'"
              :label="'Kiểu nhà'"
              :class="'form-group-hor'"
              @update-value="val => updateByCheckboxGroup(HOUSE_STYLE, val)"
            />

            <!--              Mở rộng-->
            <dynamic-form
              :id="'product-form-morong'"
              :value="value.expand_style"
              :type="'radio'"
              :label="'Mở rộng'"
              :class="'form-group-hor'"
              :options="EXPAND_STYLE"
              @update-value="val => value.expand_style = val"
            />

            <!--              Chi tiết mở rộng-->
            <dynamic-form
              v-if="value.expand_style"
              :id="'product-form-chitietmorong'"
              :value="value.expand_style_info"
              :type="'text'"
              append-text="m"
              :label="'Chi tiết mở rộng'"
              :class="'form-group-hor'"
              @update-value="val => value.expand_style_info = val"
            />

            <!--            Đặc biệt-->
            <dynamic-form
              :id="'product-form-datbiet'"
              :value="specialList"
              :options="SPECIAL"
              :type="'checkbox-group'"
              :label="'Đặc biệt'"
              :class="'form-group-hor'"
              @update-value="val => updateByCheckboxGroup(SPECIAL, val)"
            />

            <!--              Hoa  hồng-->
            <dynamic-form
              :id="'product-form-hoahong'"
              :value="value.commission"
              :type="'radio'"
              :label="'Hoa hồng'"
              :class="'form-group-hor'"
              :options="COMMISSION"
              :required="required.commission ? 'required' : ''"
              @update-value="val => value.commission = val"
            />

            <!--              Chi tiết hoa hồng TL-->
            <dynamic-form
              v-if="value.commission === 'TL'"
              :id="'product-form-chitiethoahongTL'"
              :value="value.input_tl"
              :type="'text'"
              :label="'Nội dung TL'"
              :class="'form-group-hor'"
              @update-value="val => value.input_tl = val"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BButton, BCard, BCol, BFormInput, BInputGroup, BRow, VBTooltip,
} from 'bootstrap-vue'
import { computed, reactive, ref } from '@vue/composition-api'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import useProductCreate from '@/views/product/create/useProductCreate'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  components: {
    BInputGroup,
    BFormInput,
    vSelect,
    BBadge,
    BCard,
    BCol,
    BRow,
    DynamicForm,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {
      },
    },
  },
  computed: {
    currencyList() {
      // if (this.productMenuType === 'hired') {
      //   return this.CURRENCY.filter(item => item.validBy.includes('million')) || []
      // }
      // return this.CURRENCY.filter(item => item.validBy.includes('vnd')) || []
      return this.CURRENCY.filter(item => item.validBy.includes('vnd')) || []
    },
    houseStyleList() {
      const style = [
        this.value.terrace ? 'terrace' : '',
        this.value.basement ? 'basement' : '',
        this.value.badger ? 'badger' : '',
        this.value.elevator ? 'elevator' : '',
      ]
      return style || []
    },
    specialList() {
      const style = [
        this.value.is_hot ? 'is_hot' : '',
        this.value.has_corner ? 'has_corner' : '',
        this.value.show_in_web ? 'show_in_web' : '',
      ]
      return style || []
    },
  },
  watch: {
    currencyList: {
      immediate: true,
      handler(n) {
        if (!n || !n[0]) return
        this.value.currency = n[0].value || ''
      },
    },
    value: {
      deep: true,
      handler(n) {
        if (!n) return
        this.formErrorText = undefined
        Object.keys(this.required)
          .forEach(key => {
            if (!n[key]) {
              this.formErrorText = this.formErrorText || []
              this.formErrorText.push(this.required[key])
            }
          })
        this.$emit('update-form-status', this.formErrorText)
      },
    },
  },
  created() {
    this.$call(store.dispatch('app/fetchUsers'))
      .then(res => {
        if (!res.data) return
        const user = getUserData()
        const found = res.data.find(item => item.id.toString() === user.id.toString())
        if (found !== undefined) {
          this.value.ref = found.id
          this.value.contact_id = found.id
        }
      })
  },
  methods: {
    getStatusCheckDuplicateBtn() {
      switch (this.addressStatus) {
        case undefined:
        case null:
          return 'success'
        case '':
          return 'primary'
        default:
          return 'warning'
      }
    },
    handleCheckProductUnique() {
      if (!this.value.type || !this.value.street_id || !this.value.house_number) {
        this.$toastr('Vui lòng nhập loại, đường và số nhà', 'danger')
        return
      }
      this.$call(this.checkProductUnique(this.address, res => {
        const error = res.data.message || ''
        if (error) {
          this.duplicateError = error
          this.$toastr(error, 'warning')
        } else {
          this.$showNoti('Địa chỉ sản phẩm là duy nhất')
        }
        this.addressStatus = error || ''
        this.$emit('handle-duplicate-error', error)
      }))
    },
    updateByCheckboxGroup(options, checkedList) {
      if (!options.length) return
      options.forEach(item => {
        this.value[item.value] = checkedList.includes(item.value)
      })
    },
  },
  setup(props) {
    const {
      productMenuType,
      productMenuTypeVI,
      districts,
      wards,
      streets,
      checkProductUnique,
    } = useProductCreate()

    const users = computed(() => store.state.app.users)

    const required = reactive({
      formality: 'Hình thức là bắt buộc',
      type: 'Loại là bắt buộc',
      province_id: 'Tỉnh là bắt buộc',
      district_id: 'Quận/huyện là bắt buộc',
      ward_id: 'Phường/xã là bắt buộc',
      street_id: 'Đường là bắt buộc',
      house_number: 'Số nhà là bắt buộc',
      width: 'Ngang là bắt buộc',
      length: 'Dài là bắt buộc',
      floor_number: computed(() => (props.value.type !== 'san-thuong' ? 'Số lầu là bắt buộc' : '')),
      room_number_total: computed(() => (props.value.type === 'khach-san' ? 'Số phòng là bắt buộc' : '')),
      acreage: 'Diện tích là bắt buộc',
      input_price: 'Giá là bắt buộc',
      transfer_price: computed(() => (props.value.formality === 'sang-nhuong' ? 'Giá sang nhượng là bắt buộc' : '')),
      type_of_payment: 'Kiểu thanh toán là bắt buộc',
      // host_name: 'Tên chủ nhà là bắt buộc',
      host_phone1: 'SĐT chủ nhà là bắt buộc',
      source_id: 'Nguồn là bắt buộc',
      contact_id: 'Người hỗ trợ là bắt buộc',
      commission: 'Hoa hồng là bắt buộc',
    })

    const tooltipPriceDisplayCustomer = ['[Thanh toán là m2]',
      'Giá tổng = Giá x Diện tích',
      '[Thanh toán là tổng/tháng]',
      'Giá tổng = Giá nhập',
      '[Diện tích]',
      'Diện tích = Ngang x Dài x (Lầu + 1)',
    ].join('<br/>')

    const address = reactive({
      province_id: computed(() => props.value.province_id),
      district_id: computed(() => props.value.district_id),
      ward_id: computed(() => props.value.ward_id),
      street_id: computed(() => props.value.street_id),
      house_number: computed(() => props.value.house_number),
      formality: computed(() => props.value.formality),
      type: computed(() => props.value.type),
      product_type: ref('townhouse'),
    })
    const addressStatus = ref(undefined) // [undefined, '', error]
    const duplicateError = ref('')

    return {
      duplicateError,
      required,
      address,
      users,
      tooltipPriceDisplayCustomer,
      productMenuType,
      productMenuTypeVI,
      addressStatus,
      districts,
      wards,
      streets,
      //
      checkProductUnique,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';
</style>
